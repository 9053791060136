import React, { useState, useRef, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
//import CustomEditor from '@wollo-lib/ckeditor5-classic-simpleupload';
//import SimpleUploadAdapter from '@wollo-lib/ckeditor5-classic-simpleupload/ckeditor5-upload/src/adapters/simpleuploadadapter'
//import {InlineEditor as CustomEditor}  from '@wollo-lib/ckeditor5-inline-placeholder';
import translations from 'ckeditor5/translations/de.js';

import 'ckeditor5/ckeditor5.css';
import { Placeholder } from './CKEditorPlaceholder/placeholder'

import {
    ClassicEditor,
    AccessibilityHelp,
    Autoformat,
    AutoImage,
    Autosave,
    BlockQuote,
    Bold,
    CloudServices,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    Heading,
    Highlight,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    MediaEmbed,
    Paragraph,
    PasteFromOffice,
    SelectAll,
    Strikethrough,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    TodoList,
    Underline,
    Undo,
    SimpleUploadAdapter

} from 'ckeditor5';





import useTexts from '../lib20/useTexts.jsx';

import { baseUrl, api } from '../config.jsx'


export const CKEditorUpload = (props) => {
    const { value, onChange, onType, name, uploadUrl, onInit, onFocus, className, readOnly = false, placeholders = null, autoSave = 0 } = props
    const texts = useTexts('CKEditor')
    const editorContainerRef = useRef(null);
    const editorRef = useRef(null);
    const theEditor=useRef(null)
    const lastSaved = useRef('')
    const [isLayoutReady, setIsLayoutReady] = useState(false);
    const autoSaveInterval = useRef(null)
    useEffect(() => {
        setIsLayoutReady(true);

        return () => setIsLayoutReady(false);
    }, []);


    useEffect(()=>{
        if(theEditor.current)
        {
            const editor= theEditor.current
            const toolbarElement = editor.ui.view.toolbar.element;
            if(readOnly)
            {
                editor.enableReadOnlyMode('ro')
                toolbarElement.style.display = 'none';
            }
            else
            {
                editor.disableReadOnlyMode('ro')
                toolbarElement.style.display = 'flex';
            }
        }
    },[theEditor.current,readOnly])

    const editorConfig = {
        
        toolbar: {
            items: [
                'undo',
                'redo',
                '|',
                'heading',
                '|',
                'fontSize',
                'fontFamily',
                'fontColor',
                'fontBackgroundColor',
                '|',
                'bold',
                'italic',
                'underline',
                'strikethrough',
                '|',
                'link',
                'mediaEmbed',
                'insertTable',
                'highlight',
                'blockQuote',
                '|',
                'bulletedList',
                'numberedList',
                'todoList',
                'outdent',
                'indent',

            ],
            shouldNotGroupWhenFull: false
        },
        plugins: [
            AccessibilityHelp,
            Autoformat,
            AutoImage,
            Autosave,
            BlockQuote,
            Bold,
            CloudServices,
            Essentials,
            FontBackgroundColor,
            FontColor,
            FontFamily,
            FontSize,
            Heading,
            Highlight,
            ImageBlock,
            ImageCaption,
            ImageInline,
            ImageInsertViaUrl,
            ImageResize,
            ImageStyle,
            ImageTextAlternative,
            ImageToolbar,
            ImageUpload,
            Indent,
            IndentBlock,
            Italic,
            Link,
            LinkImage,
            List,
            ListProperties,
            MediaEmbed,
            Paragraph,
            PasteFromOffice,
            SelectAll,
            Strikethrough,
            Table,
            TableCaption,
            TableCellProperties,
            TableColumnResize,
            TableProperties,
            TableToolbar,
            TextTransformation,
            TodoList,
            Underline,
            Undo,
            SimpleUploadAdapter,
            Placeholder

        ],
        simpleUpload: {
            // The URL that the images are uploaded to.
            uploadUrl: uploadUrl,

            // Enable the XMLHttpRequest.withCredentials property.
            withCredentials: true,


        },
        fontFamily: {
            supportAllValues: true
        },
        fontSize: {
            options: [10, 12, 14, 'default', 18, 20, 22],
            supportAllValues: true
        },
        heading: {
            options: [
                {
                    model: 'paragraph',
                    title: 'Paragraph',
                    class: 'ck-heading_paragraph'
                },
                {
                    model: 'heading1',
                    view: 'h1',
                    title: 'Heading 1',
                    class: 'ck-heading_heading1'
                },
                {
                    model: 'heading2',
                    view: 'h2',
                    title: 'Heading 2',
                    class: 'ck-heading_heading2'
                },
                {
                    model: 'heading3',
                    view: 'h3',
                    title: 'Heading 3',
                    class: 'ck-heading_heading3'
                },
                {
                    model: 'heading4',
                    view: 'h4',
                    title: 'Heading 4',
                    class: 'ck-heading_heading4'
                },
                {
                    model: 'heading5',
                    view: 'h5',
                    title: 'Heading 5',
                    class: 'ck-heading_heading5'
                },
                {
                    model: 'heading6',
                    view: 'h6',
                    title: 'Heading 6',
                    class: 'ck-heading_heading6'
                }
            ]
        },
        image: {
            toolbar: placeholders ? [
                'toggleImageCaption',
                'imageTextAlternative',
                '|',
                'imageStyle:inline',
                'imageStyle:wrapText',
                'imageStyle:breakText',
                '|',
                'resizeImage'
            ] :
                [
                    'toggleImageCaption',
                    'imageTextAlternative',
                    '|',
                    'imageStyle:inline',
                    'imageStyle:wrapText',
                    'imageStyle:breakText',
                    '|',
                    'resizeImage',

                ]
        },
        placeholderProps: {
            types: placeholders ? placeholders : [],
            name: 'Platzhalter',
            openBracket: "{{",
            closeBracket: "}}",

        },


        initialData:
            '',
        language: 'de',
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
            decorators: {
                toggleDownloadable: {
                    mode: 'manual',
                    label: 'Downloadable',
                    attributes: {
                        download: 'file'
                    }
                }
            }
        },
        list: {
            properties: {
                styles: true,
                startIndex: true,
                reversed: true
            }
        },
        placeholder: 'Type or paste your content here!',
        table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
        },
        translations: [translations]
    };
    if (placeholders && placeholders.length > 0) {
        editorConfig.toolbar.items = ['placeholder', '|', ...editorConfig.toolbar.items]
    }
    const myEditor = useRef(null)
    return (
        <div>
            <div className="main-container">
                <div className="editor-container editor-container_classic-editor" ref={editorContainerRef}>
                    <div className="editor-container__editor">
                        <div ref={editorRef}>{isLayoutReady &&
                            <CKEditor
                                readOnly={true}
                                editor={ClassicEditor}
                                config={editorConfig}
                                data={value}
                                onError={(error, details) =>
                                    console.error(error, details)

                                }
                                onReady={editor => {
                                    theEditor.current=editor
                                    if (onInit)
                                        onInit(editor)
                                   

                                    console.log('Editor is ready to use!', editor)
                                    myEditor.current = editor
                                    if (autoSave > 0 && onChange && !autoSaveInterval.current) {
                                        console.log('autosave', autoSave)
                                        autoSaveInterval.current = setInterval(() => {

                                            const data = myEditor.current.getData()
                                            //console.log('test save',data,lastSaved.current )
                                            if (data !== lastSaved.current) {
                                                //console.log('save')
                                                lastSaved.current = data
                                                onChange({}, { value: data, name: name, autoSave: true })
                                            }
                                        }, autoSave)
                                    }
                                   



                                }
                                }

                                onChange={
                                    (event, editor) => {
                                        if (onType) {
                                            const data = editor.getData();
                                            onType(event, { value: data, name: name })
                                        }
                                    }}
                                onBlur={(event, editor) => {
                                    if (onChange) {
                                        const data = editor.getData();
                                        onChange(event, { value: data, name: name })
                                    }

                                }}
                                onFocus={(event, editor) => {
                                    if (onFocus)
                                        onFocus(event, editor)
                                }}
                                


                            />
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default CKEditorUpload



