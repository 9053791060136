
import React,{ useState, useEffect, useRef }    from    'react';
import {Loader,Button,  Segment}                                 from    'semantic-ui-react'
import {usePageSelect,useWindowDimensions}      from    '@wollo-lib/kpe-context' //'../../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import EditFilter                               from    '../modals/EditFilterSingle'
import OutputMenu from '../../../output/OutputMenu';
import useContextMenu                         from '../modals/ContextMenu';
import MyTabulator,{
    autoNumFormatter, 
    autoNumEditFormatter
}                                               from    '@wollo-lib/wollo-tabulator'//'../../../lib/MyTabulator'


import  useTexts                                from    '../../../lib20/useTexts'
import {useHistory}                             from    "react-router-dom";
import { bannerFormatter }                      from    '../../../lib/formatters';


const Table=(props)=>
{
    const texts=useTexts('/show/group')
    const {UID,timestamp,setModalParams, modalParams,functions,achievements,tabRef}= props
    const history= useHistory()
    const [members,setMembers]=useState('')
    const [filterOpen,setFilterOpen]=useState(false)
    const rowContextMenu=useContextMenu({setModalParams})

    const jsonFields= JSON.stringify([
        {path:'$.firstName',alias:'firstName'},
        {path:'$.lastName', alias: 'lastName'},
        {path:'$.banner', alias: 'banner'},
    ])

    const [filterState,setFilterState]=useState({
        filterSource:null,
        filterAlgo:{},
        types: {person:false, guest: false, extern : false, job:true}
     })
   
    const algo={}
    Object.keys(filterState.types).forEach(type=>{
        if(filterState.types[type])
            algo[type]=filterState.filterAlgo
 
    })
    
    const ajaxParams={
        Timestamp:timestamp,
        type:'["job"]',
        Data:jsonFields, 
        groupBanner:true,
        dataFilter: JSON.stringify(algo)
    }

    const {height}=useWindowDimensions()
    const {onScroll,isLoading,totalCount}=usePageSelect({select:`/kpe20/persons/${UID}`,urlPara:ajaxParams, setData:setMembers, pageSize:50, clientFilter:true})
  


    const cellClick=(cell)=>
    {
        let row=cell.getRow()
        const rowData=row.getData()
        const field=cell.getField()
        if(['Title','banner'].includes(field))
        {
            history.push('/show/job_guest/'+rowData.UID)
        }
        else if(cell.getField()==='pGroup')
        {
            history.push('/show/group/'+rowData.UIDgroup)
        }
        if(['firstName','lastName'].includes(cell.getField()))
        {
            history.push('/show/person/main/'+rowData.UIDBelongsTo)
        }
        
    }
    
    useEffect(()=>
    {
        if(UID)
            setFilterState(state=>({...state,filterSource:UID}))
    },
    [UID])
  

    useEffect(()=>{
        if(modalParams.length>0)
        {
            setModalParams({modify:{filterState:filterState, functions: functions, achievements: achievements}})
        }
    },[filterState, functions, achievements])

    

    const toggleFilterOpen=()=>
    {
        setFilterOpen(state=>!state)
    }

    const listColumns=[
        
        {formatter:autoNumEditFormatter, width:70, hozAlign:"right", frozen:true, field:'UID', download:false}, //incrementing row number
      //  {formatter:(cell, formatterParams)=>('<i class="icon edit"></i>'), field:'UID', width:38, hozAlign:"center", frozen:true}, // edit 
        {formatter:bannerFormatter, width:70, hozAlign:"right", field:'groupBanner' , download:false},
        { title:  texts.JobType, field: 'Title', width: 200,  headerFilter:"input"},        
        { title:  texts.GroupName, field: 'pGroup',  headerFilter:"input"},
        {formatter:bannerFormatter, width:70, hozAlign:"right", field:'banner', download:false},
        { title:  texts.FirstName, field: 'firstName', width: 120,  headerFilter:"input"},
        { title:  texts.LastName, field: 'lastName', width: 120,  headerFilter:"input"},

    ]
         return(
           
             <>
                <Segment secondary>
                    {texts.TabMemberExplanation}
                </Segment>
                <Button 
                    icon='filter' 
                    circular
                    basic
                    onClick={toggleFilterOpen}
                /> 
                {isLoading ? <Loader active inline size='small'/> : totalCount}&nbsp;{texts.jobCount}
                {filterOpen &&
                    <EditFilter
                        functions={functions}
                        achievements={achievements}
                        filterState={filterState}
                        setFilterState={setFilterState}
                        type='job'
                        UID={UID}

                    />
                }
                <MyTabulator
                    rowContextMenu= {rowContextMenu}
                    index='UID'
                    columns={listColumns}
                    headerFilterPlaceholder={texts.headerFilterPlaceholder}
                    data={members}
                    onClick={cellClick}             
                    compact="very"
                    striped
                    layout="fitColumns"     
                    height={Math.max(height-250,500)}         
                    replaceMode={true}
                    onScroll={onScroll}  
                    tabRef={tabRef}
 
                />
            </>

        )


}

export default Table

