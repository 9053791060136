// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.placeholder.ck-widget {
    background: #b1b1af;
    padding: 4px 2px;
    outline-offset: -2px;
    line-height: 1em;
    margin: 0 1px;
  }
  `, "",{"version":3,"sources":["webpack://./src/lib/CKEditorPlaceholder/theme/placeholder.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;IAChB,aAAa;EACf","sourcesContent":[".placeholder.ck-widget {\n    background: #b1b1af;\n    padding: 4px 2px;\n    outline-offset: -2px;\n    line-height: 1em;\n    margin: 0 1px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
