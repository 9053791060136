import React, {useState, useEffect} from 'react'
import {Popup, Form, Modal, Label, Header}  from 'semantic-ui-react'
import {useApi} from  '@wollo-lib/kpe-context'  //'../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import { useCheckForm, SearchSelect} from '@wollo-lib/wollo-react'
import useTexts from '../lib20/useTexts'
import {useHistory} from 'react-router-dom'
import {useConfig} from '../lib20/KPE20context'



const ModalListDList=(props) => {
    const {children, resetModal,modalParams, modalSubmit,modalDefaults}=props
    const {UID,createNew,type,admin,tabRef}=modalParams[0]
    const {UIDdefault,defaultType}=modalDefaults
    const {apiSelect, apiInsert, apiDelete}=useApi()
    const texts=useTexts('/edit/modal/'+type)
    const config=useConfig()

    const history=useHistory()
    const [list, setList] = useState({})
    const [group, setGroup] =useState({UID:null})
    const [groupOptions, setGroupOptions]=useState([])
    const [force,setForce]=useState(false)
    const [responsible,setResponsible]=useState({UID:null})
    const [ignorePersons,setIgnorePersons]=useState(false)
    const [persons,setPersons]=useState([])

    useEffect(()=>
    {
        const getList = async () => {
            const result = await apiSelect(`/kpe20/${type}/${UID}?parent=true`, 'kpe20')
            if (result.success) {
                setList({...result.result.Data, UID: UID})
                setGroup(result.result?.owner ?? {UID:null})
                setResponsible(result.result.member ?? {UID:null})
            }
        }
        if (!createNew) {
            getList()
        }
        else
        {
            if(defaultType==='group')
            {
                if(groupOptions.find(go=>go.value===UIDdefault))
                    setGroup({UID:UIDdefault})
                else
                    setGroup({UID:null})
            }
        }
    
    }, [UID,groupOptions])

    useEffect(()=>{
        if(tabRef?.current && createNew)
        {
            const myPersons=tabRef.current.getData("active")
                .reduce((result,current)=>{
                if(!result.find(p=>p.UIDBelongsTo===current.UIDBelongsTo))
                    return [...result,current]
                else
                    return result
            },[])
            .sort((a,b)=>{
                const lsort=a.lastName.localeCompare(b.lastName,config.locale)
                if(lsort===0)
                    return a.firstName.localeCompare(b.firstName,config.locale)
                else
                    return lsort
            })
            setPersons(myPersons)
        }
    },[tabRef?.current, createNew])

    // retrieve the groups the user has a job in
    // if no job found, deny adding List
    useEffect(()=>{
        const getJobs=async ()=>
        {
            const resJobs=await apiSelect(`/kpe20/person/jobgroups/changeable/${config.orgaUser.UID}`,'kpe20')
            if(resJobs.success)
            {
                
                setGroupOptions(resJobs.result
                   .map(job => ({value:job.UID,key:job.UID,text:`${job.Title} ${job.Display}`})))
            }
        }
        getJobs()
    },[])
    
 
    const {errors,checkSubmit}=useCheckForm(list,
        [
            {name: 'name', error: texts.errorName ,test:
                (val) => (val!==undefined && val !== '')
            },
           
        ])
    
    const {errors:groupErrors,checkSubmit:checkGroupSubmit}=useCheckForm(group,
        [
            {name: 'UID', error: texts.errorGroup ,test:(val)=>{
                    return val!==undefined && val!=='' && val!==null
            }}
        ])
     
    const {errors:responsibleErrors,checkSubmit:checkResponsibleSubmit}=useCheckForm(responsible,
            [
                {name: 'UID', error: texts.errorProvided ,test:(val)=>{
                        return (createNew || (val!==undefined && val!=='' && val!==null))
                }}
            ])

    const handleChange=(ev, {name, value})=>
    {
        if(name==='name')
        setList((m)=>(
            {
                ...m,
                'name' : value
            }
        ))
        if(name==='group')
        {
            
            if(createNew || window.confirm(texts.shareUpdate))
                setGroup({UID:value})
        }
        if(name==='responsible')
        {
            if(window.confirm(texts.responsibleUpdate))
            setResponsible({UID:value})
        }
    }


    modalSubmit.current=async (event)=> {

        // you have to call the checks in seperate statements, not like if(!checkSubmit() || !checkGroupSubmit()), in this case the optemiser is calling onfly the first check
        // and will not call the second one, if the first one in the or statement is already true
        const check1=checkSubmit()
        const check2=checkGroupSubmit()
        const check3=checkResponsibleSubmit()
        if(!check1 || !check2 || !check3)
        {
            return
        }
        else {
            let result
            if(createNew)
                result=await apiInsert(`/kpe20/${type}/${group.UID}`, list)
            else 
                result=await apiInsert(`/kpe20/${type}/${group.UID}?user=${responsible.UID}`, {UID:UID,...list})
            if(result.success)
            {
                // now add the persons, if they are availlable and ignorePersons=false
                if((type==='email' || type==='list')
                    && !ignorePersons
                    && persons.length>0
                    && createNew
                )
                {
 
                    if(persons.length>0)
                        await apiInsert(`/kpe20/${type}/person/${result.result.UID}`,persons.map(p=>(p.UIDBelongsTo)))
                }

                // now send the user to the new crewated object
                if(type!=='dlist')
                    history.push(`/edit/${type}/${result.result.UID}`)
                else
                    history.push(`/edit/dlist/sources/${result.result.UID}`)

            }
            else
            {
                window.alert('Error: '+result.message)
            }
        }
        resetModal()
    }
    
    const deleteList=async ()=>
    {
        if(window.confirm(texts.confirm))
        {
            const extra= force ? '?force=1' : ''
            const result=await apiDelete(`/kpe20/${type}/${UID}${extra}`)
            if(result.success)
            {
                resetModal()
                history.push('/')
            }
            else
            {
                window.alert(result.message)
            }
        }
    }

    return (<>
        <Modal.Header>
            {createNew ? texts.HeaderEditNew : texts.HeaderEdit}
        </Modal.Header>
        <Modal.Content>

            <Form>
              
                <Form.Input 
                    label = {texts.name} 
                    placeholder = {texts.name}
                    name ="name"
                    onChange = {handleChange}
                    value = {list.name}
                    error={errors.name}
                    fluid
                />
                { 
                    (admin || createNew) &&

                    <Form.Select
                        label = {texts.Group}
                        placeholder = {texts.Group}
                        api = "kpe20"
                        name = "group"
                        value = {group?.UID}
                        options= {groupOptions}
                        onChange={handleChange}
                        error={groupErrors.UID}
                    />
                }
                {
                    (admin && !createNew) &&
                    <Form.Field
                        label={texts.responsible}
                        placeholder={texts.responsible}
                        control={SearchSelect}
                        api="kpe20"
                        SuggestUrl={'/kpe20/SearchData?types='+JSON.stringify([ 'person'])}
                        Value2TitleUrl='/kpe20/Value2Title'
                        value={responsible.UID}
                        name='responsible'
                        autoSelect
                        onChange={handleChange}
                        error={responsibleErrors.responsible}
                        SuggestMap={(el)=>({key:el.myKey,title:el.title,value:el.value})}
                    /> 
                }
                {
                    (createNew) ? '':
                    <Form.Group>
                        <Form.Button
                            icon='trash'
                            content={texts.delete}
                            color='red'
                            onClick={deleteList}
                        />
                        { type==='email' ? '':
                            <Popup
                                trigger={<Form.Checkbox
                                    content={texts.force}
                                    checked={force}
                                    onClick={e=>force ? setForce(false): setForce(true)}
                                    label={texts.force}
                                    toggle
                                />}
                                content={texts.forceExplain}
                            />
                        }
                    </Form.Group>
                }
               

            </Form>
        </Modal.Content>
        {children}
            {
                (createNew && tabRef?.current  && type !=='dlist') &&
                <Modal.Content>
                    <Form>
                        <Header as='h4' content={texts.headerPersons}/>
                        <Form.Checkbox 
                            label={texts.ignorePersons} 
                            checked={ignorePersons}
                            toggle 
                            onChange={(e, data) => setIgnorePersons(data.checked)}
                        />
                        {
                            (!ignorePersons && persons.length)&&
                                persons.map(p=>(<Label size='tiny' content={`${p.firstName} ${p.lastName}`}/>))


                        }
                    </Form>
                </Modal.Content>
                    
                    
            }


    </>)

}
export default ModalListDList