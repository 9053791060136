import React,{ useState, useEffect, useMemo }  from 'react'
import {Icon,Button,Header,Segment,Container,Form,Popup,Label,Image,Table,Checkbox,Loader} from 'semantic-ui-react'
import {useApi, useWindowDimensions} from  '@wollo-lib/kpe-context'  //'../../kpe-context/src/index.js' //'@wollo-lib/kpe-context'
import {useConfig,useRoot,useWs} from '../../lib20/KPE20context'

import useTexts from '../../lib20/useTexts'
import { BrowserRouter as Router,  Switch, Route, useHistory, useParams, useLocation } from 'react-router-dom'

import {CKEditorUpload as TextEditor}  from '../../lib/CKeditor'
//import {CKLazy as TextEditor}  from '../../lib/CKLazy' //lazy loading
import  {baseUrl,api}  from  '../../config.jsx'
import ModalList from '../ModalLists'
import MenuEdit from './MenuEdit.jsx'
import RecipientList from './RecipientList'


const Email=(props)=>
{
    const {modalParams, setModalParams,functions,achievements,extraMenu, setExtraMenu,setRailPath, setContainerWidth}=props
    const {UID}= useParams()
    const {apiSelect,apiDelete, apiInsert,apiUpdate, apiPutFile}=useApi()
    const texts=useTexts('/edit/email')
    const [email,setEmail]=useState({addressOption:'firstMail', status: 'prepare'})
    const [shares, setShares] =useState([])
    const [group,setGroup]= useState({})
    const [owner,setOwner]=useState({})
    const [recipients,setRecipients]=useState([])
    const [added,setAdded]=useState([])
    const [deleted,setDeleted]=useState([])
    const [writeable, setWriteable] = useState(false)
    const [admin, setAdmin] = useState(false)
    const [loading,setLoading]=useState(false)
    const [stateLoading,setStateLoading]=useState(false)
    const [isLoading,setIsLoading]=useState(false)
    const [toBeSaved, setToBeSaved]=useState(false)
    const [root]=useRoot()
    const history=useHistory()
    const config=useConfig()
    const { width } = useWindowDimensions()




    const PopupHide = (props) =>
    (
        // create a popup component, which hides on read only objects
        (writeable) &&
        <Popup {...props} />

    )

    const EditorPlaceholder=useMemo(()=> {
        if(config)
        {
            let addonPlaceholder= []
            if(config.addons)
                addonPlaceholder = config.addons.filter(addon=>addon.mailLink).map(addon=> (addon.mailLink.display))
            return [...Object.keys(config.EmailGenderPlaceholder),...Object.values(config.EmailPlaceholder), ...addonPlaceholder]
        }
        else
            return []
    }
    ,[config])

    const statusColor={
        prepare: 'blue',
        sending: 'olive',
        sent: 'green',
        pause: 'grey'
    }


    // web socket message handler
    const messageHandler=async (value) =>// websocket message handler
    {
      
        if(value.added)
        {
            setAdded(value.added)
            setRecipients(old=>(
                [...old,...value.added].sort(
                    (a,b)=>`${a.MemberData.lastName} ${a.MemberData.firstName}`
                            .localeCompare(`${b.MemberData.lastName} ${b.MemberData.firstName}`,config.locale))))
        }
        if(value.deleted)
        {        
            const myDeleted=value.deleted.map(el=>({UID:el.UID}))
            setDeleted(myDeleted) 
            setRecipients(old=>old.filter(el=>myDeleted.findIndex(del=>del.UID===el.UID)===-1))
            setStateLoading(false)
        }
        if(value.Data)
        {

            setEmail(value.Data.Data)
            if(value.Data.member)
                setOwner(value.Data.member)
            if(value.Data.owner)
                setGroup(value.Data.owner)
            if(value.Data.admin)
                setAdmin(value.Data.admin)
            if(value.Data.writeable)
                setWriteable(value.Data.writeable)
        }
        if(value.sender)
        {
            setGroup(value.sender)
        }
        if(value.reloadList)
        {   
            setIsLoading(true)
            const result=await apiSelect('/kpe20/email/persons/'+UID)
            if(result.success && result.result.length>0)
            {
                setAdded(result.result.map(el=>({UID:el.UID})))
                setRecipients(result.result)
            }
            setIsLoading(false)
        }
        if(value.shareAdded)
        {
            setShares(old=>[...old,value.shareAdded])
         }
        if(value.sharesDeleted)
        {
            setShares(old=>(old.filter(el=>!value.sharesDeleted.find(f=>el.UID===f.UID))))
        }
        if(value.personStatus)
        {
            setRecipients(old=>old.map(r=>(r.UID===value.personStatus.UIDperson ? {...r,Data:value.personStatus.Data} : r)))
        }
        if(value.reset)
        {
            setRecipients(old=>old.map(r=>({...r,Data:{status:'added', email: null}})))
            setStateLoading(false)
        }
        if(value.committed)
        {
            // reset loading state
            setStateLoading(false)
            setToBeSaved(false)
        }


    }
    const {timestamp,setTimestamp}=useWs({UIDchanged:UID,cb:messageHandler,UIDmonitor:UID, updateTimeLimit: 2000})





    const uploadAttachment=async (e)=> {
        setStateLoading(true)
        const myFile = e.target.files[0]
        const result=await apiPutFile('/kpe20/files/public/365/'+UID+'/?width=2048',myFile,myFile.name,'','kpe20') 
        if(result.success)
        {
            let fileEntry
            if(result.urls.length>1)
            {
                if(result.Data[1].converted)
                    fileEntry={name:result.name,url:result.urls[0],link: result.urls[0],faksimile:result.urls[1],type:'link'}
                else
                    fileEntry={name:result.name,url:result.urls[1],link: result.urls[0],faksimile:result.urls[1],type:'link'}
            }
            else
            {
                if(myFile.type==='image/svg+xml')
                    fileEntry={name:result.name,url:result.urls[1],link: result.urls[0],faksimile:result.urls[0],type:'link'}
                else
                    fileEntry={name:result.name,url:result.urls[0],link: result.urls[0],faksimile:null,type:'link'}
            }
            const attachments=email.attachments ? email.attachments: [] 
            attachments.push(fileEntry)
            apiUpdate('/kpe20/email/'+UID,{attachments:attachments})
        }

        setStateLoading(false)
    }


    

    // get the E-mail
    useEffect(()=>{
        const getEmail=async()=>
        {
            const myEmail=await apiSelect('/kpe20/email/'+UID,'kpe20')
            if(myEmail.success)
            {
                setEmail(myEmail.result.Data)
                setGroup(myEmail.result.owner)
                setOwner(myEmail.result.member)
                setAdmin(myEmail.result.admin)
                setWriteable(myEmail.result.writeable)
                setRailPath(myEmail.result.UIDgroup)
            }
        } 
        if(UID && UID!=='new')
            getEmail()
    },[UID, modalParams])        // Das mit email.attachments finde ich nicht schön, behebt aber den Aktualisierungsfehler. Funktionierende Änderung erbeten. Gruß Moritz

    useEffect(()=>
    {
        const getShares=async ()=>
        {
            const result= await apiSelect(`/kpe20/email/shares/${UID}`)
            if(result && result.success)
            {
                setShares(result.result)
            }
        }
        if(UID)
            getShares()
    },[UID,timestamp])

    useEffect(()=>{
        if(admin)
        {
            setExtraMenu({
                action:'replace',
                menus:
                    [
                        {menu:MenuEdit,props:{UID,timestamp,functions,achievements,shares,type:'email',admin, key:'EmailEdit'}},
                    ]
            })
        }
        else
        {
            setExtraMenu({action:'replace',menus:[]})
        }
        return ()=>setExtraMenu({action:'replace',menus:[]})
    },
    [UID,functions,achievements,timestamp, shares, admin])

    useEffect(()=>{
        setContainerWidth(1600)
    },[])

    const mailChange=(ev,{name,value,autoSave=false})=>
    {
        //done by web socket setEmail(email=>({...email,[name]:value}))
        if(name==='reset')
        {
            setStateLoading(true)
            if(admin && !window.confirm(texts.confirmReset))
                return
            
        }
        if(name!=='text')
            setStateLoading(true)
        apiUpdate(`/kpe20/email/${UID}/?autoSave=${autoSave}`,{[name]:value})
        
    }



    const changeLink=(ev,{index,checked})=>
    {
        const type= checked ? 'link' :'embed'
        const attachments=email.attachments.map((a,i)=>(i===index ? {...a,type:type} : a))
        // done by web socket setEmail(email=>({...email,attachments:attachments}))
        apiUpdate('/kpe20/email/'+UID,{attachments:attachments})
    }

    const deleteAttachment=async (ev,{index})=>
    {
        setStateLoading(true)
        const aFile=email.attachments[index]
        const attachments=email.attachments.filter((a,i)=>i!==index)
        apiUpdate('/kpe20/email/'+UID,{attachments:attachments})
        // delete as well the link file and the faksimile file, if they exists
        const uName=aFile.url.replace(`https://${api}.${baseUrl}/public/365/${UID}/`,'')
        apiDelete(`/kpe20/files/public/365/${UID}/${uName}`)
        let lName 
        let fName  
        if(aFile.link )
        {
            lName=aFile.link.replace(`https://${api}.${baseUrl}/public/365/${UID}/`,'')
            if(lName!== uName)
                await apiDelete(`/kpe20/files/public/365/${UID}/${lName}`)
        }
        if(aFile.faksimile )
        {
            fName=aFile.faksimile.replace(`https://${api}.${baseUrl}/public/365/${UID}/`,'')
            if(fName!== uName && fName !==lName )
                await apiDelete(`/kpe20/files/public/365/${UID}/${fName}`)
        }
        console.log(index)
        //setEmail(e => ({...e, attachments: e.attachments.filter((a,i) => i !== index)}))
        setStateLoading(false)
            
    
    }

    const editMail = () => {
        setModalParams({
            addLevel:{
                component:ModalList,
                UID:UID,
                type:'email',
                admin,
                tabRef: null
            }
        })
    }

    const hasAdded=recipients.find(r=>r.Data.status!=='sent')
    const hasSent= recipients.find(r=>r.Data.status==='sent')

   
    return (
        <Container>
            { (email && texts.statusOptions) ? <>
                <Segment key='3'>
                    <Header as='h2' >
                        <span>
                            <PopupHide 
                                content={texts.edit} 
                                trigger={
                                    <Icon 
                                        name='edit' 
                                        inline
                                        onClick={editMail} 
                                        className='editIconColor'
                                />}
                            />
                            
                            {texts.Header} <a
                                onClick={()=>{history.push('/show/group/members/'+group?.UID)}}>
                                    {group?.Title} {group?.Display}
                            </a>
                        </span>
                    </Header>
                    <Label color={statusColor[email.status]} basic size='large' ribbon> 
                        <Loader active={stateLoading} inline/>
                        <Icon name='eye'/>
                        {texts.statusOptions[email.status]}
                    </Label>
                <Segment inverted color='blue'>
                    <Form size='small' inverted>
                        <Form.Group stackable>
                        {
                            (email && email.status==='prepare'  && hasAdded)  &&
                            <Button
                                icon='send'
                                color='green'
                                content={<><Loader active={stateLoading} inline/> {texts.sendMail} </>}
                                onClick={
                                ev=> mailChange({},{name:'status',value:'sending', autoSave:false})
                                }
                                key='send'
                            />
                        }{
                            (email && email.status==='sending')  &&
                            <Button
                                icon='send'
                                color='red'
                                content={<><Loader active={stateLoading} inline/> {texts.pauseMail}</>}
                                onClick={
                                ev=> mailChange({},{name:'status',value:'prepare', autoSave:false})
                                }
                                key='pause'
                            /> 
                        }
                        {
                            (email &&  email.status==='sent' && hasAdded )  &&
                            <Button
                                icon='send'
                                color='green'
                                content={<><Loader active={stateLoading} inline/> {texts.sendMail}</>}
                                onClick={
                                ev=> mailChange({},{name:'status',value:'sending', autoSave:false})
                                }
                                key='unpause'
                            /> 


                        }
                        {
                            hasSent  &&
                            <Button
                                icon='send'
                                color='red'
                                content={<><Loader active={stateLoading} inline/> {texts.resetMail}</>}
                                onClick={
                                ev=> mailChange({},{name:'reset',value:true})
                                }
                                key='reset'
                            /> 
                        }
                            <Form.Select
                                    selection
                                    label={texts.addressOption}
                                    options={Object.entries(texts.addressOptions).map(([key,text],Index)=>(
                                        {
                                            key:Index,
                                            text:text,
                                            value:key 
                                        }
                                    ))}
                                    value={email.addressOption}
                                    onChange={mailChange}
                                    name='addressOption'
                                    key='select'
                                    width={6}
                                    inline
                            />    
                        </Form.Group>
                    </Form>
                </Segment>
            </Segment>        
            <Segment>
    
                <RecipientList  
                    UID={UID}
                    timestamp={timestamp}
                    recipients={recipients}
                    setRecipients={setRecipients}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    deleted={deleted}
                    setDeleted={setDeleted}
                    added={added}
                    setAdded={setAdded}
                    functions={functions}
                    achievements={achievements}
                    setModalParams={setModalParams}
                    writeable={writeable}

                />
                
                <Header as ="h3">
                        <PopupHide 
                            content={texts.edit} 
                            trigger={
                                <Icon 
                                    name='edit' 
                                    onClick={editMail} 
                                className='editIconColor'
                            />}
                        />
                        
                        {texts.Subject}: {email.name}
                </Header>

                <Header as ="h5">
                    
                    {texts.Author}: {owner?.Title} {owner?.Display}
                
                </Header>



            
            
        
                    <Segment raised key='text' >
                        <Form>
                            <Popup
                                trigger={<Icon name='save' size='large' color={toBeSaved ? 'red' : 'grey' }/>}
                                content={texts.toBeSaved}
                            />
                            <Form.Field
                                label={texts.mailText}
                                control={TextEditor}
                                uploadUrl={'https://'+api+'.'+baseUrl+'/kpe20/files/editor/365/'+UID}
                                onChange={mailChange}
                                value={email.text}
                                name='text'
                                placeholders={EditorPlaceholder}
                                readOnly={!writeable || (email && email.status !=='prepare')}
                                autoSave={5000}
                                onType={(ev,{value})=>{
                                    if(email && email.text && value!==email.text) setToBeSaved(true)
                                }}
                            />
                        </Form>
                        
                    </Segment>
                

                
    
                        
                    <Segment key='4'>         
                        <Header as='h4' content={texts.Attachments}/>
                        {(!email.attachments || email.attachments.length === 0) ? null : 
                            <Table>
                                {/* {console.log(email.attachments)} */}
                                <Table.Body>
                                    {email.attachments.map((attachment,Index)=>
                                        <Table.Row key={Index}>
                                            <Table.Cell>
                                                <a  href={attachment.url} target='_blank'>{attachment.name}</a>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Checkbox
                                                    label={texts.AsLink} 
                                                    checked={attachment.type==='link'}
                                                    onChange={changeLink} 
                                                    index={Index} 
                                                    disabled={!writeable}
                                                />
                                            </Table.Cell>
                                            <Table.Cell>
                                            {
                                                attachment.faksimile &&
                                                <Image  src={attachment.faksimile} size='tiny'/>
                                            }
                                            </Table.Cell>
                                            <Table.Cell>
                                                {writeable &&
                                                    <Button circular color='red'  basic icon='trash' onClick={deleteAttachment} index={Index}/>
                                                }
                                            </Table.Cell>
                    
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        }
                        {writeable &&
                            <Button  htmlFor='file2' type='button' as='label' width={1} loading={loading}>
                                {loading===true ? '' : <>
                                <input type='file' id='file2' accept='image/*,.pdf' style={{display: 'none'}} onChange={uploadAttachment} />
                                        <Icon name='upload' color='green'/> {texts.uploadAttachment}</>
                                }
                            </Button>
                        }
                    </Segment>     
    

                        </Segment>


            </>:''
        } 
        </Container>
    )

}
export default Email