import {baseUrl,api} from '../config'


export const bannerFormatter=( cell)=>
{
    const value=cell.getValue()
    if(value && value.length>0)
    {
        const [UID,]=value.split('/')
        if(UID!=='null')
        {
            const result=`<img src="https://${api}.${baseUrl}/kpe20/files/${value}" height="15em"/>`
            return result
        }
    }
    else
    {
        return ''
    }
}